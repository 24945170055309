import { navigate } from "@reach/router";
import { discordLink } from '../constants';

type GotoFunc = (replace?: boolean) => void;

function gotoFactory(path: string): GotoFunc {
    return (replace?: boolean) => {
        if (replace) {
            navigate(path, { replace: true });
            return;
        }
        navigate(path);
    };
}

/**
 * @param history History object from `useHistory()`
 * @param replace Don't allow user to go back once navigated.
 */
export const gotoAccount = gotoFactory('/account/');
export const gotoLogin = gotoFactory('/login/');

export const gotoSignup = gotoFactory('/signup/');

export const gotoVerify = gotoFactory('/verify/');

export const gotoRoot = gotoFactory('/');

export const joinDiscord = () => window.open(discordLink, '_blank');
