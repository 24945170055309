import React, { ReactElement, useEffect, useState } from "react";
import Layout from "../components/layout";
import { navigate } from "@reach/router";
import SEO from "../components/seo";
import useAccount from "../hooks/useAccount";
import { Button } from "luxd";
import {
    FaDiscord,
    FaWindows,
    FaApple,
    FaDownload,
    FaUbuntu,
} from "react-icons/fa";
import { joinDiscord } from "../utils/navigation";
import { SplashContainer } from "../components/SplashContainer";
import { graphql, Link, useStaticQuery } from "gatsby";
import { PreviewItem } from "../sections/Preview";
import { ScreenshotFrame } from "../components/ScreenshotFrame";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import { SocialLinks } from "../components/SocialLinks";
import { getDownloadLink } from "../hooks/useNewDownloadUrl";

const Download = ({ location }: any): ReactElement => {
    const [dismiss, setDismiss] = useState(location.state?.dismiss ?? false);

    const [user, loading, error] = useAccount();
    const mixpanel = useMixpanel();

    const trackDownload = (os: "win32" | "macOS" | "linux") => {
        mixpanel.track("download", {
            os,
        });
        mixpanel.people.increment('DownloadCount');
        mixpanel.people.append('DownloadOS', os);
    };

    const [winUrl, setWinUrl] = useState<string | undefined>();
    const [macUrl, setMacUrl] = useState<string | undefined>();


    useEffect(() => {
        getDownloadLink('Windows').then((link) => {
            console.log(link);
            setWinUrl(link);
        });
        getDownloadLink('macOS').then((link) => {
            console.log(link);
            setMacUrl(link);
        });
    }, []);

    const isAuthed = user !== undefined;

    const clear = () => {
        if (!isAuthed) {
            navigate("/signup", {
                state: { redirect: { to: "/download", from: "/download" } },
            });
        } else {
            setDismiss(true);
        }
    };

    const upgrade = (plan: string) => {
        if (isAuthed) {
            navigate("/upgrade", { state: { plan } });
        } else {
            navigate("/signup", {
                state: { redirect: "/upgrade", plan },
            });
        }
    };

    const data = useStaticQuery(graphql`
        {
            allContentfulLayoutPreview {
                nodes {
                    items {
                        image {
                            file {
                                url
                            }
                        }
                        heading
                        subheading {
                            childrenContentfulRichText {
                                html
                            }
                        }
                    }
                }
            }
        }
    `);

    const content: PreviewItem[] =
        data.allContentfulLayoutPreview.nodes[0].items;

    return (
        <Layout>
            <SEO
                title="Download"
                description="Download the Streamlux desktop app for free to level up your stream."
            />
            <div className="bg-dark">
                <SplashContainer className="h-full flex-grow p-4 md:p-16 items-center flex flex-col">
                    <div className="p-8 rounded-md bg-darkGray flex flex-col items-center my-auto md:px-16 w-full md:w-auto">
                        <img
                            src={require("assets/images/logo.svg")}
                            alt="Streamlux logo"
                            className="w-64 mx-auto"
                        />
                        <div className="flex flex-row justify-center my-8">
                            <h1 className="text-offWhite text-4xl font-display text-center">
                                Downloads
                            </h1>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-2 w-full gap-8 md:gap-16 max-w-screen-lg text-offWhite bg-darkGray mb-8 mx-auto">
                            <div className="rounded-lg flex flex-col space-y-4 items-center bg-dark md:w-72 p-8">
                                <FaWindows fontSize="120px" />
                                <div className="text-xl font-display self-center">
                                    Windows 7, 8, 10
                                </div>
                                <a
                                    className="w-full"
                                    // download
                                    // type='application/vnd.microsoft.portable-executable'
                                    href={winUrl}
                                >
                                    <Button
                                        icon={<FaDownload />}
                                        className="w-full"
                                        onClick={() => trackDownload("win32")}
                                    >
                                        Download
                                    </Button>
                                </a>
                            </div>
                            <div className="rounded-lg flex flex-col space-y-4 items-center bg-dark md:w-72 p-8">
                                <FaApple fontSize="120px" />
                                <div className="text-xl font-display self-center">
                                    macOS 10.10+
                                </div>
                                <a
                                    className="w-full"
                                    href={macUrl}
                                >
                                    <Button
                                        icon={<FaDownload />}
                                        className="w-full"
                                        onClick={() => trackDownload("macOS")}
                                    >
                                        Download
                                    </Button>
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-8 items-center">
                            <p className="text-offWhite opacity-70">
                                By downloading and using Streamlux, you agree to
                                the{" "}
                                <Link
                                    to="/privacy"
                                    className="text-secondary hover:underline"
                                >
                                    privacy policy
                                </Link>{" "}
                                and{" "}
                                <Link
                                    to="/terms"
                                    className="text-secondary hover:underline"
                                >
                                    terms of service
                                </Link>
                                .
                            </p>
                            <Button
                                className="w-min"
                                size="normal"
                                icon={<FaDiscord />}
                                onClick={() => joinDiscord()}
                            >
                                Join our Discord
                            </Button>
                            <SocialLinks iconClassnames="w-8" />
                        </div>
                    </div>
                </SplashContainer>
                <div
                    className="flex flex-col items-center mx-auto my-16"
                    style={{ maxWidth: "80vw" }}
                >
                    <ScreenshotFrame url={content[2]?.image.file.url} />
                </div>
            </div>
        </Layout>
    );
};

export default Download;
