import React, { ReactElement, FC } from "react";


type ScreenshotFrameProps = {
    url: string;
};

export const ScreenshotFrame: FC<ScreenshotFrameProps> = ({
    url,
}): ReactElement => {
    return (
        <div className="flex flex-col w-auto">
            <div className="flex flex-col p-4 lg:p-8 rounded-3xl rounded-b-none bg-primary h-full w-auto items-center">
                <img
                    src={url}
                    className="rounded-lg max-w-full w-auto"
                    alt="Large screenshot of the Streamlux desktop application"
                />
            </div>
            <div className="h-8 lg:h-12 xl:h-16 rounded-b-3xl bg-secondary items-center flex flex-row justify-center space-x-4">
            </div>
        </div>
    );
};
