interface Release {
    url: string;
    assets_url: string;
    upload_url: string;
    html_url: string;
    id: number;
    node_id: string;
    tag_name: string;
    target_commitish: string;
    name: string;
    draft: boolean;
    prerelease: boolean;
    created_at: string;
    published_at: string;
    assets?: (AssetsEntity)[] | null;
    tarball_url: string;
    zipball_url: string;
    body: string;
}

interface AssetsEntity {
    url: string;
    id: number;
    node_id: string;
    name: string;
    label: string;
    content_type: string;
    state: string;
    size: number;
    download_count: number;
    created_at: string;
    updated_at: string;
    browser_download_url: string;
}

export async function getDownloadLink(platform: string) {
    const latestRelease: Release = await (await fetch('https://api.github.com/repos/streamlux/streamlux-releases/releases/latest', {
        method: 'GET', headers: {
            Accept: "application/vnd.github.v3+json"
        }
    })).json();

    const extension = platform === 'Windows' ? '.exe' : '.dmg';

    return latestRelease.assets?.find((asset) => asset.name.endsWith(extension))?.browser_download_url;
}
